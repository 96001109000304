(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipsen-shared/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/sport/tipsen-shared/assets/javascripts/constants.js');
"use strict";


const MatchCorrectStatus = {
  CORRECT: 0,
  NOT_CORRECT: 1,
  POSSIBLE_WIN: 2
};
const MatchResultTypes = {
  NOT_ENTERED: 0,
  ENTERED: 1,
  FINAL: 2,
  VERIFIED: 3
};
const OddsTypes = {
  HIGHEST_ODDS: 'highestOdds',
  LOWEST_ODDS: 'lowestOdds'
};
const ParticipantTypes = {
  Player: 'Player',
  Team: 'Team'
};
const RenderContext = {
  Default: 'default',
  ReduceFree: 'reduceFree',
  Result: 'result',
  MyBets: 'myBets'
};
const CouponTabActions = {
  COUPON: 'coupon',
  BEST_BET_ROW: 'best_bet_row'
};
const ResultInclinationIndexes = {
  0: 'Hemmaseger',
  1: 'Oavgjort',
  2: 'Bortaseger'
};
const RoutesClientErrorCodes = {
  1000: 'GENERAL_ERROR',
  1010: 'FETCH_DRAW_ERROR',
  1020: 'REDIRECT_DRAW_ERROR',
  1030: 'FETCH_COMPETITIONS_ERROR',
  1040: 'FETCH_RELATED_DRAWS_ERROR',
  1050: 'FETCH_JACKPOTS_ERROR',
  1090: 'FAILED_COMPOSING_PRELOADED_DRAW_STATE'
};
const RoutesClientErrors = {
  GENERAL_ERROR: {
    code: RoutesClientErrorCodes.GENERAL_ERROR,
    message: 'Just nu har vi problem med att visa kupongen. Försök igen senare.'
  },
  FETCH_DRAW_ERROR: {
    code: RoutesClientErrorCodes.FETCH_DRAW_ERROR,
    message: 'Just nu kan vi inte visa någon omgång. Försök igen senare.'
  },
  REDIRECT_DRAW_ERROR: {
    code: RoutesClientErrorCodes.REDIRECT_DRAW_ERROR,
    message: 'Ett fel uppstod vid hämtning av omgång. Försök igen senare.'
  },
  FETCH_COMPETITIONS_ERROR: {
    code: RoutesClientErrorCodes.FETCH_COMPETITIONS_ERROR,
    message: 'Just nu kan vi inte hämta tävlingsinformation. Försök igen senare.'
  },
  FETCH_RELATED_DRAWS_ERROR: {
    code: RoutesClientErrorCodes.FETCH_RELATED_DRAWS_ERROR,
    message: 'Just nu kan vi inte hämta relaterade omgångar. Försök igen senare.'
  },
  FETCH_JACKPOTS_ERROR: {
    code: RoutesClientErrorCodes.FETCH_JACKPOTS_ERROR,
    message: 'Just nu kan vi inte hämta information gällande jackpot.'
  },
  FAILED_COMPOSING_PRELOADED_DRAW_STATE: {
    code: RoutesClientErrorCodes.FAILED_COMPOSING_PRELOADED_DRAW_STATE,
    message: 'Just nu har vi problem med att visa kupongen. Försök igen senare.'
  }
};
const SessionStorageKeys = {
  ROUTE_CLIENT_PUSH: 'ROUTE_CLIENT_PUSH'
};
const SvsContactPhoneNumber = '0770-11 11 11';
const WinLevels = {
  LOW: 1000,
  MEDIUM: 20000,
  HIGH: 1000000,
  HIGHEST: 5000000,
  SHOW_ANIMATION_LEVEL: 0
};
const ScoreReductions = {
  HOME: {
    index: 0,
    sign: 'H',
    text: 'Hemma',
    ariaLabel: 'Reducera hemmavinst'
  },
  DRAW: {
    index: 1,
    sign: 'O',
    text: 'Oavgjort',
    ariaLabel: 'Reducera oavgjort resultat'
  },
  AWAY: {
    index: 2,
    sign: 'B',
    text: 'Borta',
    ariaLabel: 'Reducera bortavinst'
  }
};
const MaxOutcomeIndexes = {
  BOMBEN: 10
};
const constants = {
  CouponTabActions,
  MatchCorrectStatus,
  MatchResultTypes,
  MaxOutcomeIndexes,
  OddsTypes,
  ParticipantTypes,
  RenderContext,
  ResultInclinationIndexes,
  RoutesClientErrorCodes,
  RoutesClientErrors,
  ScoreReductions,
  SessionStorageKeys,
  SvsContactPhoneNumber,
  WinLevels
};
if (svs.isServer) {
  module.exports = constants;
} else {
  setGlobal('svs.components.sport.tipsenShared.constants', constants);
}

 })(window);